import Vue from 'vue';
import VueRouter from 'vue-router';

import Store from '../store/index';
import Front from '../layouts/Front.vue';
import Back from '../layouts/Back.vue';
import Login from '../layouts/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/bo/login',
    name: 'login_back',
    component: Login,
    meta: {
      adminPage: true,
    },
  },
  {
    path: '/bo',
    component: Back,
    meta: {
      requiresAuth: true,
      adminPage: true,
    },
    children: [
      {
        path: 'clients',
        name: 'dashboard_home',
        component: () => import(/* webpackChunkName: "back" */ '../views/bo/index'),
        children: [
          {
            path: 'edit/:clientId',
            name: 'dashboard_edit_client',
            meta: {
              showDialogEdit: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/edit_client'),
          },
          {
            path: 'new',
            name: 'dashboard_new_client',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/new_client'),
          },
          {
            path: 'view',
            name: 'dashboard_view_client',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/view_client'),
          },
        ],
      },
      {
        path: 'users',
        name: 'dashboard_users',
        component: () => import(/* webpackChunkName: "back" */ '../views/bo/users'),
        children: [
          {
            path: 'new',
            name: 'dashboard_new_user',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/new_user'),
          },
          {
            path: 'edit/:email',
            name: 'dashboard_edit_user',
            meta: {
              showDialogEdit: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/edit_user'),
          },
        ],
      },
      {
        path: 'organisations',
        name: 'dashboard_organisations',
        component: () => import(/* webpackChunkName: "back" */ '../views/bo/organisations'),
        children: [
          {
            path: 'new',
            name: 'dashboard_new_organisation',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/new_organisation'),
          },
          {
            path: 'view',
            name: 'dashboard_view_organisation',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/view_organisation'),
          },
          {
            path: 'edit/:id',
            name: 'dashboard_edit_organisation',
            meta: {
              showDialogEdit: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/edit_organisation'),
          },
        ],
      },
      {
        path: 'equipments',
        name: 'dashboard_equipments',
        component: () => import(/* webpackChunkName: "back" */ '../views/bo/equipments'),
        children: [
          {
            path: 'new',
            name: 'dashboard_new_equipment',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/new_equipment'),
          },
          {
            path: 'view',
            name: 'dashboard_view_equipment',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/view_equipment'),
          },
          {
            path: 'edit/:id',
            name: 'dashboard_edit_equipment',
            meta: {
              showDialogEdit: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/edit_equipment'),
          },
        ],
      },
      {
        path: 'contacts',
        name: 'dashboard_contacts',
        component: () => import(/* webpackChunkName: "back" */ '../views/bo/contacts'),
        children: [
          {
            path: 'new',
            name: 'dashboard_new_contact',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/new_contact'),
          },
          {
            path: 'view',
            name: 'dashboard_view_contact',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/view_contact'),
          },
          {
            path: 'edit/:id',
            name: 'dashboard_edit_contact',
            meta: {
              showDialogEdit: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/edit_contact'),
          },
        ],
      },
      {
        path: 'catalogs',
        name: 'dashboard_catalogs',
        component: () => import(/* webpackChunkName: "back" */ '../views/bo/catalogs'),
        children: [
          {
            path: 'new',
            name: 'dashboard_new_catalog',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/new_catalog'),
          },
          {
            path: 'view',
            name: 'dashboard_view_catalog',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/view_catalog'),
          },
          {
            path: 'edit/:id',
            name: 'dashboard_edit_catalog',
            meta: {
              showDialogEdit: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/edit_catalog'),
          },
        ],
      },
      {
        path: 'maintenances',
        name: 'dashboard_maintenances',
        component: () => import(/* webpackChunkName: "back" */ '../views/bo/maintenances'),
        children: [
          {
            path: 'new',
            name: 'dashboard_new_maintenance',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/new_maintenance'),
          },
          {
            path: 'view',
            name: 'dashboard_view_maintenance',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/view_maintenance'),
          },
          {
            path: 'edit/:id',
            name: 'dashboard_edit_maintenance',
            meta: {
              showDialogEdit: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/edit_maintenance'),
          },
        ],
      },
      {
        path: 'subscriptions',
        name: 'dashboard_subscriptions',
        component: () => import(/* webpackChunkName: "back" */ '../views/bo/subscriptions'),
        children: [
          {
            path: 'new',
            name: 'dashboard_new_subscription',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/new_subscription'),
          },
          {
            path: 'view',
            name: 'dashboard_view_subscription',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/view_subscription'),
          },
          {
            path: 'edit/:id',
            name: 'dashboard_edit_subscription',
            meta: {
              showDialogEdit: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/edit_subscription'),
          },
        ],
      },
      {
        path: 'propositions',
        name: 'dashboard_propositions',
        component: () => import(/* webpackChunkName: "back" */ '../views/bo/propositions'),
        children: [
          {
            path: 'new',
            name: 'dashboard_new_proposition',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/new_proposition'),
          },
          {
            path: 'view',
            name: 'dashboard_view_proposition',
            meta: {
              showDialogNew: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/view_proposition'),
          },
          {
            path: 'edit/:id',
            name: 'dashboard_edit_proposition',
            meta: {
              showDialogEdit: true,
            },
            component: () => import(/* webpackChunkName: "back" */ '../views/bo/edit_proposition'),
          },
        ],
      },
      {
        path: 'settings',
        name: 'dashboard_settings',
        component: () => import(/* webpackChunkName: "back" */ '../views/bo/settings'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login_front',
    component: Login,
  },
  {
    path: '/',
    component: Front,
    children: [
      {
        path: '',
        name: 'home',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "front" */ '../views/index'),
      },
      {
        path: 'docs',
        name: 'documents',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "front" */ '../views/documents'),
      },
      {
        path: 'maintenance',
        name: 'maintenance',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "front" */ '../views/maintenance'),
      },
      {
        path: 'hosting',
        name: 'hosting',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "front" */ '../views/hosting'),
      },
      {
        path: 'payment',
        name: 'payment',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "front" */ '../views/payment'),
      },
      {
        path: 'about',
        name: 'about',
        component: () => import(/* webpackChunkName: "front" */ '../views/about'),
      },
      {
        path: '*',
        name: '404',
        component: () => import(/* webpackChunkName: "front" */ '../views/404'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // console.log(Store);
    if (!Store.state.auth.status.loggedIn && !to.meta.adminPage) {
      next({
        name: 'login_front',
        query: { redirect: to.fullPath },
        replace: true,
      });
    } else if (!Store.state.auth.status.loggedIn && to.meta.adminPage) {
      next({
        name: 'login_back',
        query: { redirect: to.fullPath },
        replace: true,
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
