<template>
  <v-app>
    <v-navigation-drawer
      app
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      :mini-variant="mini"
      style="top:0px;"
    >
    <v-container style="display: flex;flex-direction: column;">
      <v-app-bar-nav-icon @click.stop="mini = !mini" style="align-self: end">
        <v-icon>mdi-chevron-left</v-icon>
      </v-app-bar-nav-icon>
      <v-img v-if="mini === false" src="../assets/smotly.png"/>
      <v-img v-else src="../assets/smotly_small.png"/>
    </v-container>
      <v-list dense nav>
        <v-list-item
          link
          :to="item.to"
          v-for="(item, id) in navItems"
          :key="item.title"
          exact
          :disabled="item.disabled"
          style="flex-direction: column;"
        >
        <div style="display: flex;align-self: self-start;padding-left: 10px;">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </div>
          <v-divider
            v-if="id != navItems.length-1"
            style="width: 100%;position: inherit;top: 2px;">
          </v-divider>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main app>
      <router-view />
    </v-main>

    <!--<v-footer app></v-footer>-->
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import { getSelf } from '@/services/user.service';

export default {
  name: 'Back',
  title: 'MargeCom - Dashboard',

  async mounted() {
    if (this.loggedIn) {
      try {
        const user = await getSelf();
        this.user = user.data;
        if (!this.user.admin) {
          this.logout();
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      await this.$router.push({ name: 'login_back' });
    }
  },
  data: () => ({
    navItems: [
      {
        title: 'Accueil',
        icon: 'mdi-home',
        to: { name: 'dashboard_home' },
        disabled: false,
      },
      {
        title: 'Organisations',
        icon: 'mdi-account-multiple',
        to: { name: 'dashboard_organisations' },
        disabled: false,
      },
      {
        title: 'Contacts',
        icon: 'mdi-office-building',
        to: { name: 'dashboard_contacts' },
        disabled: false,
      },
      {
        title: 'Catalogues',
        icon: 'mdi-file-document',
        to: { name: 'dashboard_catalogs' },
        disabled: false,
      },
      {
        title: 'Maintenances',
        icon: 'mdi-cogs',
        to: { name: 'dashboard_maintenances' },
        disabled: true,
      },
      {
        title: 'Equipements',
        icon: 'mdi-web',
        to: { name: 'dashboard_equipments' },
        disabled: true,
      },
      {
        title: 'Abonnements',
        icon: 'mdi-wallet-membership',
        to: { name: 'dashboard_subscriptions' },
        disabled: true,
      },
      {
        title: 'Propositions',
        icon: 'mdi-clipboard-text',
        to: { name: 'dashboard_propositions' },
        disabled: true,
      },
      /*
      {
        title: 'Clients',
        icon: 'mdi-account-multiple',
        to: { name: 'dashboard_home' },
      },
      {
        title: 'Utilisateurs',
        icon: 'mdi-contacts',
        to: { name: 'dashboard_users' },
      },
      {
        title: 'Paramètres',
        icon: 'mdi-cogs',
        to: { name: 'dashboard_settings' },
      },
      */
    ],
    drawer: null,
    mini: false,
    user: null,
  }),
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    logout() {
      this.storeLogout()
        .then(() => this.$router.push({ name: 'login_back' }))
        .catch(console.error);
    },
    ...mapActions({
      storeLogout: 'auth/logout',
    }),
  },
};
</script>
<style scoped>
  .v-list--nav {
    padding-left: 30px;
    padding-right: 30px;
  }
  .v-list--dense .v-list-item, .v-list-item--dense {
    min-height: 0px;
  }
  .v-navigation-drawer--mini-variant .v-list-item>:first-child {
    margin-left: -30px;
  }
  .v-list .v-list-item--active, .v-list .v-list-item--active .v-icon {
    color: rgb(109, 213, 204);
  }
  .theme--light.v-list-item--active:before,
  .theme--light.v-list-item--active:hover:before,
  .theme--light.v-list-item:focus:before {
    opacity: 0;
  }
  .v-list-item__title {
    font-size:16px!important;
  }
</style>
