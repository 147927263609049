import * as AuthService from '../../services/auth.service';

const userStorage = JSON.parse(localStorage.getItem('user'));
const initState = userStorage
  ? { status: { loggedIn: true }, user: userStorage }
  : { status: { loggedIn: false }, user: null };

export default {
  namespaced: true,
  state: initState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then((loggedUser) => {
        commit('loginSuccess', loggedUser);
        return Promise.resolve(loggedUser);
      }, (error) => {
        commit('loginFailure');
        return Promise.reject(error);
      });
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then((response) => {
        commit('registerSuccess');
        return Promise.resolve(response.data);
      }, (error) => {
        commit('registerFailure');
        return Promise.reject(error);
      });
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess() {
      // console.log(state);
    },
    registerFailure() {
      // console.log(state);
    },
  },
};
